import React, { useState, useEffect, useRef } from "react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Wrap,
  WrapItem,
  Spinner,
  Text,
  Badge,
  IconButton,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Input,
  Select,
  Avatar,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { FaEye, FaTrash } from "react-icons/fa";

export default function SubmittedClaims() {
  const [loading, setIsLoading] = useState(false);
  const [clientFilter, setClientFilter] = useState([]);
  const [carrierFilter, setCarrierFilter] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [selectedTab, setSelectedTab] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [claimType, setClaimType] = useState("All");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [claimToDelete, setClaimToDelete] = useState(null);
  const [statusCounts, setStatusCounts] = useState(null);

  const [tabClaims, setTabClaims] = useState({
    All: [],
    Pending: [],
    "In Progress": [],
    Approved: [],
    Credited: [],
    Rejected: [],
  });

  const toast = useToast();

  const cancelRef = useRef();
  const alertDialogRef = useRef();

  const openConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  // Fetch all claims without pagination
  const fetchData = async (status, claimType, client, carrier, searchText) => {
    try {
      console.log('Fetching claims for:', status);

      setIsLoading(true);

      const token = localStorage.getItem("accessToken");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const params = {
        status: status,
        claimType: claimType,
      };

      if (client) {
        params.client = client;
      }

      if (carrier) {
        params.carrier = carrier;
      }

      if (searchText) {
        params.searchText = searchText;
      }

      const response = await axios.get(`${constants.apiUrl}/support/submitted-claims`, {
        headers,
        withCredentials: true,
        params,
      });

      setStatusCounts(response.data.statusCounts);
      setTabClaims((prevTabClaims) => ({
        ...prevTabClaims,
        [status]: response.data.claims, // Directly setting fetched data
      }));

  

    } catch (error) {
      console.error("Error fetching claims:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilterData = async (claimType) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${constants.apiUrl}/support/fetch-claim-filters`, {
        headers,
        withCredentials: true,
        params: { claimType: claimType },
      });

      setClientFilter(response.data.clients);
      setCarrierFilter(response.data.carriers);

    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  // useEffect to fetch data when selectedTab, claimType, selectedClient, or selectedCarrier changes
  useEffect(() => {
    // Reset states when filters or tab change
    setIsLoading(true);
    setTabClaims({
      All: [],
      Pending: [],
      "In Progress": [],
      Approved: [],
      Credited: [],
      Rejected: [],
    });

    // Fetch all data with updated filters
    fetchData(selectedTab, claimType, selectedClient, selectedCarrier, searchText);
  }, [selectedTab, claimType, selectedClient, selectedCarrier, searchText]);

  // Fetch filter data whenever claimType changes
  useEffect(() => {
    fetchFilterData(claimType);
  }, [claimType]);

  // Handler for clicking the "Delete" icon
  const handleDeleteClick = (claim) => {
    setClaimToDelete(claim);
    openConfirmation();
  };

  // Handler for confirming the deletion
  const handleConfirmDelete = async () => {
    closeConfirmation();
    if (claimToDelete) {
      try {
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.delete(`${constants.apiUrl}/admin/delete-claim`, {
          headers,
          data: { trackingNumber: claimToDelete.trackingNumber, type: claimToDelete.type },
        });

        console.log("Claim deleted successfully:", response.data);

        if (response.status === 200) {
          toast({
            position: "bottom-right",
            title: "Claim Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }

        // After successful deletion, refetch data
        fetchData(selectedTab, claimType, selectedClient, selectedCarrier, searchText);

      } catch (error) {
        console.error("Error deleting claim:", error);
        if (error.response && error.response.status === 403) {
          toast({
            position: "bottom-right",
            title: "Forbidden: You do not have permission to delete the claim",
            description: error.response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            position: "bottom-right",
            title: "Error: Failed to delete the claim",
            description: error.response?.data?.message || "An unexpected error occurred.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
  };

  function RenderClaims({ claims }) {
    const borderColor = useColorModeValue("white", "gray.800");

    return (
      <Wrap spacing={4} style={{ whiteSpace: 'nowrap' }}>
        {claims.length === 0 ? (
          <Text>No submitted claims found.</Text>
        ) : (
          claims.map((claim) => (
            <WrapItem key={`${claim.trackingNumber}-${claim.type}`}>
              <Box
                borderWidth="1px"
                borderRadius="lg"
                p={4}
                shadow="md"
                bg={borderColor}
                position="relative"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="250px"
              >
                <Box mt="auto" alignSelf="flex-end">
                  <Link
                    to={`/support/view-claim?trackingNumber=${claim.trackingNumber}&type=${claim.type}`}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      icon={<FaEye />}
                      aria-label="View"
                      colorScheme="blue"
                      variant="ghost"
                      size="sm"
                    />
                  </Link>
                  <IconButton
                    icon={<FaTrash />}
                    aria-label="Delete"
                    colorScheme="red"
                    variant="ghost"
                    size="sm"
                    onClick={() => handleDeleteClick(claim)}
                  />
                </Box>
                <Box mb={2}>
                  <Text fontWeight="bold">Tracking Number:</Text>
                  <Text>{claim.trackingNumber}</Text>
                </Box>
                <Box mb={2} display="flex" alignItems="center" gap={2}>
                  <Badge
                    colorScheme={
                      claim.type === "Damaged" ? "orange" : 
                      claim.type === "Late" ? "yellow" : 
                      "blue"
                    }
                  >
                    {claim.type}
                  </Badge>
                  <Badge
                    colorScheme={claim.status === "Approved" || claim.status === "Credited" ? "green" : "red"}
                  >
                    {claim.status}
                  </Badge>
                </Box>
                {claim.raisedBy && (
                  <Box mb={2} display="flex" alignItems="center" gap={2}>
                    <Avatar size="sm" name={claim.raisedBy} />
                    <Text>{claim.raisedBy}</Text>
                  </Box>
                )}
              </Box>
            </WrapItem>
          ))
        )}
      </Wrap>
    );
  }

  const handleTabChange = (index) => {
    const statuses = ["All", "Pending", "In Progress", "Approved", "Credited", "Rejected"];
    const selectedStatus = statuses[index];
    setSelectedTab(selectedStatus);
    // The useEffect will handle fetching data
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} px="30px">
      <Flex mb={4} gap={4}>
        <Input
          placeholder="Search by tracking number"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Select
          placeholder="Filter by Client"
          value={selectedClient}
          onChange={(e) => setSelectedClient(e.target.value)}
        >
          {clientFilter.map((client) => (
            <option key={client} value={client}>
              {client}
            </option>
          ))}
        </Select>
        <Select
          placeholder="Filter by Carrier"
          value={selectedCarrier}
          onChange={(e) => setSelectedCarrier(e.target.value)}
        >
          {carrierFilter.map((carrier) => (
            <option key={carrier} value={carrier}>
              {carrier}
            </option>
          ))}
        </Select>
        <Select
          value={claimType}
          onChange={(e) => setClaimType(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Damaged">Damaged</option>
          <option value="Late">Late</option>
          <option value="Lost">Lost</option>
        </Select>
      </Flex>
      <Tabs index={["All", "Pending", "In Progress", "Approved", "Credited", "Rejected"].indexOf(selectedTab)} onChange={handleTabChange}>
        <TabList justifyContent={"space-between"}>
          {["All", "Pending", "In Progress", "Approved", "Credited", "Rejected"].map((tabName) => (
            <Tab key={tabName}>{tabName} {statusCounts && statusCounts[tabName] ? `(${statusCounts[tabName]})` : "(0)"}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {["All", "Pending", "In Progress", "Approved", "Credited", "Rejected"].map((tabName) => (
            <TabPanel key={tabName}>
              {loading ? (
                <Flex justifyContent="center" alignItems="center">
                  <Spinner />
                </Flex>
              ) : (
                <RenderClaims claims={tabClaims[tabName]} />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <AlertDialog
        isOpen={isConfirmationOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeConfirmation}
        motionPreset="slideInBottom"
        isCentered
        size="md"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Delete
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this claim? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeConfirmation}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
