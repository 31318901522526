import React, { useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tfoot,
  Tr,
  Input,
  Box,
  FormHelperText,
  FormControl,
  FormLabel,
  Button,
  useToast
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { IoDocumentTextOutline } from "react-icons/io5";
// Custom components
import Card from "components/card/Card";

export default function ItemTable({ claimsCharge,orderItems, trackingNumber }) {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const columnsData = [
    {
      Header: "SKU",
      accessor: "SKU",
    },
    {
      Header: "Weight",
      accessor: "Weight",
    },
    {
      Header: "Product Description",
      accessor: "Description",
    },
    {
      Header: "Quantity",
      accessor: "Quantity",
    },
    {
      Header: "Enter Product Description",
      accessor: "inputProductDescription",
      width: 300
    },
    {
      Header: "Enter Cost of single piece",
      accessor: "inputCostPerPiece",
      width:150
    },
    {
      Header: "Total Cost of Product",
      accessor: (row) => {
        const costPerPiece = row.inputCostPerPiece ? parseFloat(row.inputCostPerPiece) : parseFloat(row.CostPrice);
        const quantity = parseFloat(row.Quantity);
        return isNaN(costPerPiece) || isNaN(quantity)
          ? ""
          : (costPerPiece * quantity).toFixed(2);
      },
    },
    {
      Header: "Sales Price",
      accessor: "Price",
    },
  ];

  // Inside your component
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tableData, setTableData] = useState(orderItems);
  const currentPage = 1;
  const pageSize = 25;
  const totalPages = 1;
  const toast = useToast();
  const handleFileChange = (event) => {
    const newSelectedFiles = Array.from(event.target.files);
    setSelectedFiles(newSelectedFiles);
  };

   // Calculate the total weight, total items, and total cost
   const totalWeight = tableData.reduce((total, item) => total + parseFloat(item.Weight), 0).toFixed(2);
   const totalItems = tableData.reduce((total, item) => total + parseInt(item.Quantity), 0);
   const totalCost = tableData.reduce((total, item) => {
     const costPerPiece = item.inputCostPerPiece ? parseFloat(item.inputCostPerPiece) : parseFloat(item.CostPrice);
     const quantity = parseFloat(item.Quantity);
     if (!isNaN(costPerPiece) && !isNaN(quantity)) {
       return total + costPerPiece * quantity;
     }
     return total;
   }, 0).toFixed(2);
 
   const totalSalesCost = tableData.reduce((total, item) => total + parseFloat(item.Price), 0).toFixed(2);

  const submitLostClaim = (e) => {
    e.preventDefault();
  
    if(totalCost <= parseFloat(claimsCharge)){
      toast({
        position: "top",
        title: "Error Submitting Claim: The total claims value is less that the claims charge.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const token = localStorage.getItem("accessToken");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

     // Create a FormData object
  const formData = new FormData();

  // Loop through the selectedFiles and append each file to the FormData
  for (const file of selectedFiles) {
    formData.append("LostclaimFiles", file);
  }

  // Add other data to the FormData, if needed
  formData.append("data", JSON.stringify(tableData));
  formData.append("trackingNumber", trackingNumber);
  formData.append("userId", userDetails.userId);
  setIsSubmitting(true); // Show the loader
    // Call the API with the provided order number
      axios
    .post(
      `${constants.apiUrl}/customer/add-lost-claim`,
      formData,
      { headers, withCredentials: true }
    )
    .then((response) => {
      if(response.status === 200){
        toast({
          position: "top",
          title: "Claim Submitted Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }else{
        toast({
          position: "top",
          title: "Error Submitting Claim, Please try again! ",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    })
    .catch((error) => {
      const errorMessage = error.response.data.message || "Error Submitting Claim, Please try again!";
      toast({
        position: "top",
        title: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error submitting claim:", error);
    }).finally(() =>{
      setIsSubmitting(false);
    });

  };

  const handleInputChange = (e, row) => {
    const { name, value } = e.target;

    // Find the item in the orderItems array by SKU
    const updatedOrderItems = tableData.map((item) => {
      if (item.SKU === row.original.SKU) {
        // Update the corresponding field in the item
        return { ...item, [name]: value };
      }
      return item;
    });

    // Update the orderItems state with the edited data
    setTableData(updatedOrderItems);
  };

 

  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
      manualPagination: true,
      pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex direction="column" w="50%" px="0px" ml={4}>
        {/* Upload Section */}
        <FormControl>
          <FormLabel htmlFor="fileUpload">Upload Purchase Invoice</FormLabel>
          <Input
            type="file"
            id="fileUpload"
            accept="image/*,.pdf"
            multiple
            onChange={handleFileChange}
          />
          <FormHelperText>Select one or more images or PDFs</FormHelperText>
        </FormControl>
        {/* Selected Files Section */}
        {selectedFiles.length > 0 && (
          <Flex direction="column" w="50%" px="0px" ml={4} mt={4}>
            <Text fontWeight="bold">Selected Files:</Text>
            <Box>
              {selectedFiles.map((file, index) => (
                <Flex key={index} alignItems="center">
                  <Icon as={IoDocumentTextOutline} color="gray.500" mr={2} />
                  <Text>{file.name}</Text>
                </Flex>
              ))}
            </Box>
          </Flex>
        )}
      </Flex>

      <Flex
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
          <Box overflowX="auto" maxWidth="100%">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb="24px"
          style={{ overflowX: "auto" }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                    minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                    whiteSpace="normal"
                    wordBreak="break-word"
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon as={FiArrowDown} />
                          ) : (
                            <Icon as={FiArrowUp} />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <Tr>
                <Td colSpan={columnsData.length} textAlign="center">
                  No data available.
                </Td>
              </Tr>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let cellContent = cell.value;

                      if (cell.column.Header === "Enter Product Description") {
                        cellContent = (
                          <Input
                            name={cell.column.id} // Use accessor as the name
                            value={cell.row.original.inputProductDescription}
                            onChange={(e) => handleInputChange(e, row)}
                            borderColor={borderColor}
                          />
                        );
                      } else if (
                        cell.column.Header === "Enter Cost of single piece"
                      ) {
                        cellContent = (
                          <Input
                            type="Number"
                            step={0.01}
                            min={0}
                            name={cell.column.id}
                            value={
                              cell.row.original.inputCostPerPiece !== undefined
                                ? cell.row.original.inputCostPerPiece
                                : cell.row.original.CostPrice
                            }
                            
                            onChange={(e) => handleInputChange(e, row)}
                            borderColor={borderColor}
                          />
                        );
                      }else if (
                        cell.column.Header === "Sales Price"
                      ) {
                        cellContent = (
                          <Input
                            type="Number"
                            step={0.01}
                            min={0}
                            name={cell.column.id}
                            value={
                              cell.row.original.Price
                            }
                            
                            onChange={(e) => handleInputChange(e, row)}
                            borderColor={borderColor}
                          />
                        );
                      }  else {
                        cellContent = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {cell.value}
                          </Text>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          maxH="30px !important"
                          py="8px"
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {cellContent}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Total</Th>
              <Th>{totalWeight}</Th>
              <Th></Th>
              <Th>{totalItems}</Th>
              <Th></Th>
              <Th></Th>
              <Th>{totalCost}</Th>
            
              <Th textAlign={"center"}>{isNaN(totalSalesCost) ? 0 : totalSalesCost}</Th>
            </Tr>
          </Tfoot>
        </Table>
        </Box>
      </Flex>
     
      <Flex p={4} backgroundColor={"white"}>
      <Button
          variant={"brand"}
          mb={4}
          ml="auto"
          isLoading={isSubmitting}
          loadingText="Submitting Claim"
          _loading={{
            _hover: {
              backgroundColor: "green", // Change the color on hover as well
            },
          }}
          onClick={submitLostClaim}
        >
            Submit Claim
        </Button>
        </Flex>
    </Card>
  );
}
