import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp, FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";

export default function ComplexTable() {
  const columnsData = [
    {
      Header: "SKU",
      accessor: "sku",
    },
    {
      Header: "Location Name",
      accessor: "locationName",
    },
    {
      Header: "WAREHOUSE",
      accessor: "warehouse",
    },

    {
      Header: "CLIENT",
      accessor: "clientCode",
    },
    {
      Header: "STOCK",
      accessor: "stock",
    },
    {
      Header: "COST PRICE",
      accessor: "costPrice",
    },
    {
      Header: "VALUE ONHAND",
      accessor: "valueOnHand",
    },
    {
      Header: "DESPATCHED",
      accessor: "despatched",
    },
    {
      Header: "VELOCITY",
      accessor: "velocity",
    },
  ];

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [infoOptions, setInfoOptions] = useState({
    stockOptions: [],
    warehouseOptions: [],
    clientOptions: [],
    velocityOptions: [],
  });

  const [selectedFilters, setSelectedFilters] = useState({
    velocity: "",
    warehouse: "",
    client: "",
    stock: "",
  });

  // Define event handlers for filter options
  const handleWarehouseChange = (value) => {
    setSelectedFilters({ ...selectedFilters, warehouse: value });
  };

  const handleClientChange = (value) => {
    setSelectedFilters({ ...selectedFilters, client: value });
  };

  const handleVelocityChange = (value) => {
    setSelectedFilters({ ...selectedFilters, velocity: value });
  };

  const handleStockChange = (value) => {
    setSelectedFilters({ ...selectedFilters, stock: value });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/support/stock-location-report`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
            warehouse: selectedFilters.warehouse,
            client: selectedFilters.client,
            stock: selectedFilters.stock,
            velocity: selectedFilters.velocity,
          },
        }
      );

      const data = response.data.StockInfoListData;
      setTableData(data);
      setTotalPages(response.data.totalPages);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching Stock Info Report data:", error);
      // Handle error if necessary
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    // Fetch all dropdown options
    // Assuming you have an API endpoint to submit product data
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${constants.apiUrl}/support/stockinfo-dropdowns`, { headers })
      .then((response) => {
        const {
          velocityOptions,
          warehouseOptions,
          clientOptions,
          stockOptions,
        } = response.data;
        setInfoOptions({
          stockOptions: stockOptions.length > 0 ? stockOptions : [],
          warehouseOptions: warehouseOptions.length > 0 ? warehouseOptions : [],
          clientOptions: clientOptions.length > 0 ? clientOptions : [],
          velocityOptions: velocityOptions.length > 0 ? velocityOptions : [],
        });
      })
      .catch((error) =>
        console.error("Error fetching Stock info dropdown options:", error)
      );
  }, []);
  // Fetch data for the initial page

  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForPage(currentPage, pageSize, searchQuery);
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    }
  }, [currentPage, pageSize, searchQuery, selectedFilters]);

  const exportToCSV = () => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/support/stock-location-report`, {
          headers,
          withCredentials: true,
          params: {
            // page: page,
            // pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
            warehouse: selectedFilters.warehouse,
            client: selectedFilters.client,
            stock: selectedFilters.stock,
            velocity: selectedFilters.velocity,
            exportCSV: true,
          },
        })
        .then((response) => {
          const data = response.data.StockInfoListData;

          const columnsData = [
            {
              Header: "SKU",
              accessor: "sku",
            },
            {
              Header: "Location Name",
              accessor: "locationName",
            },
            {
              Header: "WAREHOUSE",
              accessor: "warehouse",
            },
        
            {
              Header: "CLIENT",
              accessor: "clientCode",
            },
            {
              Header: "STOCK",
              accessor: "stock",
            },
            {
              Header: "COST PRICE",
              accessor: "costPrice",
            },
            {
              Header: "VALUE ONHAND",
              accessor: "valueOnHand",
            },
            {
              Header: "DESPATCHED",
              accessor: "despatched",
            },
            {
              Header: "VELOCITY",
              accessor: "velocity",
            },
          ];

          const separator = ",";
          const csvContent =
            columnsData.map((column) => column.Header).join(separator) +
            "\n" +
            data
              .map((row) =>
                columnsData
                  .map((column) => {
                    let cell = row[column.accessor];
                    if (cell === null || cell === undefined) {
                      cell = "";
                    } else if (
                      typeof cell === "object" &&
                      cell.hasOwnProperty("value")
                    ) {
                      cell = cell.value;
                    }
                    cell = cell.toString().replace(/"/g, '""');
                    cell = cell.toString().replace(/€/g, "");
                    if (
                      cell.includes(separator) ||
                      cell.includes("\n") ||
                      cell.includes('"')
                    ) {
                      cell = `"${cell}"`;
                    }
                    return cell;
                  })
                  .join(separator)
              )
              .join("\n");

          const csvBlob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
          });
          const csvURL = window.URL.createObjectURL(csvBlob);

          // Generate file name
          const timestamp = new Date().toISOString().replace(/[:.-]/g, "");

         // Collecting non-empty values from selectedFilters
          const filterParts = Object.entries(selectedFilters)
          .filter(([key, value]) => value) // Filter out empty values
          .map(([key, value]) => value); // Extract the values

          // Create the fileName dynamically
          const fileName = filterParts.length > 0 
          ? `stockinfo-report-${filterParts.join('-')}-${timestamp}.csv` 
          : `stockinfo-report-${timestamp}.csv`;

          const tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", fileName);
          tempLink.click();

          resolve(); // Resolve the Promise after successful export
        })
        .catch((error) => {
          console.error("Error exporting csv data:", error);
          reject(error); // Reject the Promise if there's an error
        });
    });
  };

  // Function to handle button click event
  const handleExportButtonClick = () => {
    exportToCSV()
      .then(() => {
        console.log("CSV export completed successfully");
      })
      .catch((error) => {
        console.error("CSV export failed:", error);
      });
  };

  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
      manualPagination: true,
      pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Stock Info Report
            </Text>

            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
          </Flex>
          <Flex
            px="25px"
            justify="space-between"
            mb="10px"
            align="center"
            flexWrap="unset" // Allow wrapping of filter elements
          >
            {/* Warehouse filter */}
            <Select
              /* Select component props */
              onChange={(e) => handleWarehouseChange(e.target.value)}
              value={selectedFilters.warehouse}
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            >
              <option value="">Select Warehouse</option>
              {infoOptions.warehouseOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {/* Client filter */}
            <Select
              /* Select component props */
              onChange={(e) => handleClientChange(e.target.value)}
              value={selectedFilters.client}
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            >
              <option value="">Select Client</option>
              {infoOptions.clientOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {/* QC Type filter */}
            <Select
              /* Select component props */
              onChange={(e) => handleStockChange(e.target.value)}
              value={selectedFilters.stock}
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            >
              <option value="">{`Stock >=`}</option>
              {infoOptions.stockOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {/* Status filter */}
            <Select
              /* Select component props */
              onChange={(e) => handleVelocityChange(e.target.value)}
              value={selectedFilters.velocity}
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            >
              <option value="">Select Velocity</option>
              {infoOptions.velocityOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>

            <Icon
              as={FiDownload}
              onClick={handleExportButtonClick}
              variant="brand"
              w={"4em"}
              h={"1.5em"}
              marginTop={"5px"}
            >
              Export
            </Icon>
           
          </Flex>

          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            style={{ overflowX: "auto" }}
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let cellContent = cell.value;

                       
                          cellContent = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {cellContent}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>

          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
