import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Heading,
  Flex,
  useToast,
  Text,
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
export default function DamagedForm({ orderItems, trackingNumber, claimsCharge }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [forms, setForms] = useState([
    {
      sku: "",
      description: "",
      additionalDescription: "",
      quantity: "",
      costPrice: "",
      numberOfPieces: "",
      totalCostPrice: "",
      salesPrice: "",
      totalSalesPrice: "",
      damageDescription: "",
      images: [],
      supplierInvoice: null,
    },
  ]);
  const toast = useToast();
  const addForm = () => {
    const newForm = {
      sku: "",
      description: "",
      additionalDescription: "",
      quantity: "",
      costPrice: "",
      numberOfPieces: "",
      totalCostPrice: "",
      salesPrice: "",
      totalSalesPrice: "",
      damageDescription: "",
      images: [],
      supplierInvoice: null,
    };
    setForms([...forms, newForm]);
  };

  const removeForm = (index) => {
    const updatedForms = [...forms];
    updatedForms.splice(index, 1);
    setForms(updatedForms);
  };

  const handleInputChange = (index, field, value) => {
    const updatedForms = [...forms];
    updatedForms[index][field] = value;

    // Check if the field being updated is "sku"
    if (field === "sku") {
      // Look up the description from orderItems
      const sku = value;
      const matchingItem = orderItems.find((item) => item.SKU === sku);
      if (matchingItem) {
        updatedForms[index].description = matchingItem.Description;
        updatedForms[index].costPrice = matchingItem.CostPrice;
        updatedForms[index].quantity = 1;
        updatedForms[index].salesPrice = matchingItem.Price;
        updatedForms[index].numberOfPieces = 1;
        updatedForms[index].totalCostPrice =
          updatedForms[index].costPrice * updatedForms[index].quantity;
        updatedForms[index].totalSalesPrice =
          updatedForms[index].salesPrice * updatedForms[index].numberOfPieces;
      }
    }

    if (field === "costPrice" || field === "quantity") {
      updatedForms[index].totalCostPrice =
        updatedForms[index].costPrice * updatedForms[index].quantity;
    } else if (field === "salesPrice" || field === "numberOfPieces") {
      updatedForms[index].totalSalesPrice =
        updatedForms[index].salesPrice * updatedForms[index].numberOfPieces;
    }

    setForms(updatedForms);
  };

  const handleImageUpload = (index, files) => {
    const updatedForms = [...forms];
    updatedForms[index].images = files;
    setForms(updatedForms);
  };

  const handleInvoiceUpload = (index, file) => {
    const updatedForms = [...forms];
    updatedForms[index].supplierInvoice = file;
    setForms(updatedForms);
  };

  let totalClaimValue = 0;
  totalClaimValue = forms
    .reduce((total, item) => total + parseFloat(item.totalCostPrice), 0)
    .toFixed(2);
  if (isNaN(totalClaimValue)) {
    totalClaimValue = 0;
  }

  const submitDamagedClaim = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("accessToken");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    const formData = new FormData();

    if (!forms[0].sku || forms.length === 0) {
      toast({
        position: "top",
        title: "Error Submitting Claim, Please add at least one SKU!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

      // Check if entered SKU exists in orderItems
      const skuExists = forms.some(form => orderItems.some(item => item.SKU === form.sku));
    
      if (!skuExists) {
        const availableSKUs = orderItems.map(item => item.SKU);
        toast({
          position: "top",
          title: "Error Submitting Claim, SKU not found in Shipment!",
          description: `Available SKUs: ${availableSKUs.join(", ")}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

    // Additional validation for numberOfPieces and quantity not being equal
    const invalidForm = forms.find(
      (form) => form.numberOfPieces !== form.quantity
    );

    if (invalidForm) {
      toast({
        position: "top",
        title: `Error Submitting Claim: SKU ${invalidForm.sku} - Number of Pieces and Quantity must be equal`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if(totalClaimValue <= parseFloat(claimsCharge)){
      toast({
        position: "top",
        title:
          "Error Submitting Claim: The total claims value is less that the claims charge.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Loop through the forms
    forms.forEach((form, index) => {
      // Append images for the current form
      form.images.forEach((image, imageIndex) => {
        formData.append(
          `DamagedclaimFiles`,
          image,
          `${image.name}_${form.sku}`
        ); // Append the image with a name
      });

      // Append the supplierInvoice PDF for the current form
      if (form.supplierInvoice) {
        formData.append(
          `DamagedclaimFiles`,
          form.supplierInvoice,
          `${form.supplierInvoice.name}_${form.sku}`
        ); // Append the PDF with a name
      }
    });

    formData.append("data", JSON.stringify(forms));
    formData.append("trackingNumber", trackingNumber);
    formData.append("userId", userDetails.userId);

    try {
      setIsSubmitting(true); // Show the loader
      const response = await axios.post(
        `${constants.apiUrl}/customer/add-damaged-claim`,
        formData,
        { headers, withCredentials: true }
      );

      if (response.status === 200) {
        toast({
          position: "top",
          title: "Claim Submitted Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top",
          title: "Error Submitting Claim, Please try again!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error Submitting Claim, Please try again!";
      toast({
        position: "top",
        title: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error submitting claim:", error);
    } finally {
      setIsSubmitting(false); // Hide the loader when the submission is complete
    }
  };

  return (
    <Box p={6}>
      <Button variant={"brand"} onClick={addForm} mb={4}>
        Add More SKUs
      </Button>
      {forms.map((form, index) => (
        <Box
          key={index}
          borderWidth="1px"
          borderRadius="md"
          p={6}
          mt={4}
          position="relative"
          backgroundColor={"white"}
        >
          <Button
            onClick={() => removeForm(index)}
            position="absolute"
            top="2"
            right="2"
            variant={"brand"}
          >
            Remove
          </Button>
          <FormControl mb={6} mt={4}>
            <FormLabel>SKU</FormLabel>
            <Input
              value={form.sku}
              onChange={(e) => handleInputChange(index, "sku", e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Product Description</FormLabel>
            <Input
              value={form.description}
              onChange={(e) =>
                handleInputChange(index, "description", e.target.value)
              }
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Additional Item Description</FormLabel>
            <Input
              value={form.additionalDescription}
              onChange={(e) =>
                handleInputChange(
                  index,
                  "additionalDescription",
                  e.target.value
                )
              }
            />
          </FormControl>
          <Text fontSize="lg" fontWeight="bold">
            Cost Price
          </Text>
          <SimpleGrid columns={3} gap={4}>
            <FormControl>
              <FormLabel>Number of Pieces Damaged</FormLabel>
              <NumberInput
                value={form.quantity}
                onChange={(value) =>
                  handleInputChange(index, "quantity", value)
                }
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Cost Price of Each Item</FormLabel>
              <InputGroup>
                <InputLeftAddon>£</InputLeftAddon>
                <Input
                  value={form.costPrice}
                  onChange={(e) =>
                    handleInputChange(index, "costPrice", e.target.value)
                  }
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Total Cost Price of the Items</FormLabel>
              <InputGroup>
                <InputLeftAddon>£</InputLeftAddon>
                <Input
                  disabled
                  value={form.totalCostPrice}
                  onChange={(e) =>
                    handleInputChange(index, "totalCostPrice", e.target.value)
                  }
                />
              </InputGroup>
            </FormControl>
          </SimpleGrid>
          <Text mt={2} fontSize="lg" fontWeight="bold">
            Sales Price
          </Text>
          <SimpleGrid columns={3} gap={4}>
            <FormControl>
              <FormLabel>Number of Pieces Damaged</FormLabel>
              <NumberInput
                value={form.numberOfPieces}
                onChange={(value) =>
                  handleInputChange(index, "numberOfPieces", value)
                }
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Sales Price of Each Item</FormLabel>
              <InputGroup>
                <InputLeftAddon>£</InputLeftAddon>
                <Input
                  value={form.salesPrice}
                  onChange={(e) =>
                    handleInputChange(index, "salesPrice", e.target.value)
                  }
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Total Sales Price</FormLabel>
              <InputGroup>
                <InputLeftAddon>£</InputLeftAddon>
                <Input
                  disabled
                  value={form.totalSalesPrice}
                  onChange={(e) =>
                    handleInputChange(index, "totalSalesPrice", e.target.value)
                  }
                />
              </InputGroup>
            </FormControl>
          </SimpleGrid>
          <FormControl mt={2} mb={4}>
            <FormLabel>Description of the Damage</FormLabel>
            <Input
              value={form.damageDescription}
              onChange={(e) =>
                handleInputChange(index, "damageDescription", e.target.value)
              }
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Upload Images</FormLabel>
            <Input
              type="file"
              multiple
              onChange={(e) =>
                handleImageUpload(index, Array.from(e.target.files))
              }
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Upload Supplier Invoice (PDF)</FormLabel>
            <Input
              type="file"
              accept=".pdf"
              onChange={(e) => handleInvoiceUpload(index, e.target.files[0])}
            />
          </FormControl>
        </Box>
      ))}
      <Flex p={4} backgroundColor={"white"}>
        <Heading as="h2" size="md" mt={2} mb={4}>
          Total Claim Value: {totalClaimValue}
        </Heading>
        <Button
          variant={"brand"}
          mb={4}
          ml="auto"
          isLoading={isSubmitting}
          loadingText="Submitting Claim"
          _loading={{
            _hover: {
              backgroundColor: "green", // Change the color on hover as well
            },
          }}
          onClick={submitDamagedClaim}
        >
          Submit Claim
        </Button>
      </Flex>
    </Box>
  );
}
